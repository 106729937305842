import localStorage from '@/components/Services/storage';

export default function language({ next, to }) {
    if (to.query.hasOwnProperty('lang')) {
        let language = to.query.lang;
        if (['sr', 'de'].includes(language)) {
            localStorage.save('locale', language, true);
        } else {
            localStorage.save('locale', 'de', true);
        }
    }
  
    return next();
}