<template>
  <form id="msisdn-form">
    <label class="landing-label">
      <span class="label-title">{{ $t('pages.login.labels.phone') }}</span>
      <input type="text"
             :placeholder="$t('pages.login.inputs.placeholders.phoneNumber')"
             v-model.trim="phoneNumber"
             @keydown.enter.prevent="attemptToGetSmsCodeViaMsisdn({ phone: phoneNumber })"
             ref="phoneNumberInput"
      >
      <small v-if="user.validation_errors && user.validation_errors.phone">{{ user.validation_errors.phone[0] }}</small>
    </label>
    <small v-if="user.error && !(user.validation_errors && user.validation_errors.phone)">
        {{ user.error }}
    </small>
    <fieldset class="buttons">
      <button type="button"
              class="main-btn submit-btn phone-number"
              @click="attemptToGetSmsCodeViaMsisdn({ phone: phoneNumber })"
      >
        <span class="spinner-label">{{ $t('pages.login.buttons.submit') }}</span>
      </button>
    </fieldset>
  </form>
</template>

<script>
import MsisdnForm from './MsisdnForm';
export default MsisdnForm;
</script>
