<template>
  <div id="login-wrapper">
    <div class="left-wrapper">
      <div class="logo-wrapper">
        <a href="#">
          <img src="../../assets/logo.png" alt="logo">
        </a>
      </div>
      <div id="wrapper-form">

          <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active"
                     id="nav-home-tab"
                     data-toggle="tab"
                     href="#nav-home"
                     role="tab"
                     aria-controls="nav-home"
                     aria-selected="true"
                     @click="toggleActiveParameter('msisdn')"
                  >
                      {{ $t('pages.login.tabMsisdnName') }}
                  </a>
                  <a class="nav-item nav-link"
                     id="nav-profile-tab"
                     data-toggle="tab"
                     href="#nav-profile"
                     role="tab"
                     aria-controls="nav-profile"
                     aria-selected="false"
                     @click="toggleActiveParameter('username')"
                  >
                      {{ $t('pages.login.tabCustomerInfoName') }}
                  </a>
              </div>
          </nav>


        <h1>{{ $t('pages.login.leftTitle') }}</h1>
        <MsisdnForm v-if="msisdnLoginMethodActive && !user.isSmsCodeSent"/>
        <UsernameForm v-else-if="usernameLoginMethodActive"/>
        <SmsForm v-else-if="user.isSmsCodeSent"/>

        <ul class="lang-switcher">
          <li>
            <button type="button"
                    :class="{'active': locale === 'de'}"
                    @click="changeLanguageLogin('de')"
            >DE</button>
          </li>
          <li>
              |
          </li>
          <li>
            <button type="button"
                    :class="{'active': locale === 'sr'}"
                    @click="changeLanguageLogin('sr')"
            >SRB</button>
          </li>
        </ul>
      </div>

    </div>
    <div class="right-wrapper">
      <section class="landing-banner">
        <div class="wrapper">
          <h1 class="title">{{ $t('pages.login.rightTitle') }}</h1>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Login from './Login';
export default Login;
</script>
