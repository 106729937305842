import { store } from '@/store/store';
import localStorage from '@/components/Services/storage';

export default function auth({to, from, next}) {
    /**
     * if user is coming from mobile app
     */
     if (to.path === '/mobile-payment') {
        return next({
            path: `/mobile-payment?checkoutId=${to.query.checkoutId}`,
        });
    }
    /**
     * if user is coming from mobile app
     */
    if (to.path === '/transaction-completed-mobile') {
        return next({
            path: `/transaction-completed-mobile?resourcePath=${to.query.resourcePath}`,
        });
    }
    // Gets API token from storage if any
    const token = localStorage.get('token', true);
    const userLoggedIn = store.state.user.isLoggedIn;

    // If user is not logged in but token exist in local storage,
    // check if token is still valid and try to auto login him back
    if (token && !store.state.user.isLoggedIn) {
        console.log('try auto login user from router index.js');
        store.dispatch('notification/getAllNotifications', { token });
        store.dispatch('user/tryAutoLogin', { token }).then(() => next());
        store.dispatch('setResources');
    }
    // If user is not logged in and token doesnt exist, redirect him on login page
    if (!token && !userLoggedIn) {
        return next({
            path: '/login',
        });
    }

    /**
     * Check if user has token and he is trying to go on login page
     * If so, then redirect him instantly on home page
     */
    if (to.path === '/login') {
        if (token) {
            return next({
                path: '/',
            });
        }
    }
    // If user is logged in and token exist, let him go
    return next();
}