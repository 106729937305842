export default {
    props: ['message'],

    methods: {
        redirectAccepted() {
            this.$emit('redirectAccepted', true);
        },

        close() {
            this.$emit('close', true);
        },
    },
};
